.background {
  background-color: #12110f;
}

.orderContainer {
  text-align: left;
  margin: 1rem;
  padding: 1rem;
}

.default {
  color: #bbaa88;
}
.red {
  color: rgb(255, 0, 0);
}

.green {
  color: rgb(0, 255, 72);
}

.blue {
  color: rgb(72, 72, 247);
}
