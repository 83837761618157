.background {
  background-color: #12110f;
}

.orderContainer {
  text-align: left;
  margin: 1rem;
  padding: 1rem;
}

.tableRow {
  border: 1px solid #6f6c6c;
}

.tableCell {
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .tableCont {
    overflow-x: auto;
    margin: 0.5rem;
  }
}

.default {
  color: #bbaa88;
}
.red {
  color: rgb(255, 0, 0);
}

.green {
  color: rgb(0, 255, 72);
}

.blue {
  color: rgb(72, 72, 247);
}

.violet {
  color: rgb(238, 72, 174);
}
