.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(82, 79, 79, 0.7);
}
.container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto 0;
}
