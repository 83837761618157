* {
  color: white;
}
.table {
  margin: 1rem;
  width: 100%;
}
.tableContainer {
  /* overflow-x: scroll; */
  /* width: 100%; */
}

.tableRow {
  border: 1px solid transparent;
  padding: 5px;
  background-color: rgb(72, 69, 69);
}

.tableCell {
  padding: 10px;
}

.tableRow:nth-child(even) {
  background-color: rgb(54, 52, 52);
}
