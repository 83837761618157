.checkbox:checked {
  width: 25px;
  height: 25px;
  /* color: red; */
  accent-color: rgb(0, 255, 72);
}

.checkbox {
  width: 25px;
  height: 25px;
}
