.container {
  position: fixed;
  width: 50%;
  margin: 1rem;
  /* top: 5%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
  padding: 1rem;
  border: 1px solid #a58546;
  border-radius: 15px;
  background-color: #292929;
  cursor: default;
}

@media (max-width: 768px) {
  .container {
    /* top: 15%; */
  }
}
