/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4c4848 #2e2e2e;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #2e2e2e;
}

*::-webkit-scrollbar-thumb {
  background-color: #a28648;
  border-radius: 20px;
  border: 3px none #a28648;
}
