.tableRow {
  border: 1px solid transparent;
  padding: 5px;
  background-color: rgb(72, 69, 69);
  cursor: pointer;
}

.tableCell {
  padding: 10px;
}

.tableRow:nth-child(even) {
  background-color: rgb(54, 52, 52);
}

.link {
  color: white;
  text-decoration: none;
}
.link:hover {
  color: white;
}
.red {
  color: rgb(255, 0, 0);
}

.green {
  color: rgb(0, 255, 72);
}

.blue {
  color: rgb(72, 72, 247);
}
